/**
 * Verifica se um domínio é permitido.
 * @param {string} returnUrl URL a ser verificada.
 * @returns {boolean} true se o domínio for permitido, false caso contrário.
 */
export const checkRedirectDomain = (returnUrl?: string | null): boolean => {
  if (!returnUrl || returnUrl === '') {
    return false
  }

  const allowedDomain = 'decathlon.com.br'
  const { hostname } = new URL(returnUrl)

  return hostname.endsWith(allowedDomain)
}
